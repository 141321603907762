import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import { GoogleOAuthProvider } from '@react-oauth/google'
import MeetingPermissionsContainer from '../../../containers/meetingPermissionsContainer'

const MeetingsPermissionsPage: React.FC = () => {
  return (
    <LayoutMain title={'EDIT MEETBOT'}>
      <HelmetComponent title="Edit MeetBot"></HelmetComponent>
      <GoogleOAuthProvider
        clientId={
          process.env.REACT_APP_GOOGLE_APP_CLIENT_ID
            ? process.env.REACT_APP_GOOGLE_APP_CLIENT_ID
            : ''
        }
      >
        <MeetingPermissionsContainer />
      </GoogleOAuthProvider>
    </LayoutMain>
  )
}

export default MeetingsPermissionsPage
