import React, { useState, useEffect } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import {
  StyledMeetBotContainer,
  StyledMeetBotPermissionContent,
  StyledMeetBotPermissionLink,
  StyledMeetBotPermissionText
} from './styledComponents'
import { StyledH2 } from '../../styles/styledComponents'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

function MeetingPermissionsContainer() {
  const navigation = useNavigate()
  const initialUserProperties = {
    access_token: '',
    expires_in: 0,
    id_token: '',
    scope: '',
    token_type: ''
  }

  const SCOPES = process.env.REACT_APP_GOOGLE_APP_SCOPES

  const [emailUser, setEmailUser] = useState<any>(initialUserProperties)
  const [, setEmailProfile] = useState<any>(null)

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setEmailUser(codeResponse)
      localStorage.setItem('code', codeResponse.code)
      navigation(`/bots/create-bot/meeting`)
    },
    onError: (error) => console.log('Login Failed:', error),
    scope: SCOPES,
    flow: 'auth-code',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI
  })

  useEffect(() => {
    if (!!emailUser.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${emailUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${emailUser.access_token}`,
              Accept: 'application/json'
            }
          }
        )
        .then((res) => {
          setEmailProfile(res.data)
        })
        .catch((err) => console.log('err: ', err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailUser])

  useEffect(() => {
    if (localStorage.getItem('code')) {
      navigation(`/bots/create-bot/meeting`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledMeetBotContainer>
      <StyledH2>
        Para crear un meet bot es necesario la autentificación con tu cuenta de
        google.
      </StyledH2>
      <br />
      <p>
        <strong>Permisos requeridos</strong>
      </p>

      <StyledMeetBotPermissionContent>
        <StyledMeetBotPermissionText>
          Asociarlo con su información personal en Google
        </StyledMeetBotPermissionText>
      </StyledMeetBotPermissionContent>

      <StyledMeetBotPermissionContent>
        <StyledMeetBotPermissionText>
          Permite ver su información personal, incluidos los datos personales
          que haya hecho públicos
        </StyledMeetBotPermissionText>
      </StyledMeetBotPermissionContent>

      <StyledMeetBotPermissionContent>
        <StyledMeetBotPermissionText>
          Ver la dirección de correo electrónico principal de tu Cuenta de
          Google
        </StyledMeetBotPermissionText>
      </StyledMeetBotPermissionContent>
      <StyledMeetBotPermissionContent>
        <StyledMeetBotPermissionText>
          Ver, editar y borrar todos tus archivos de Google Drive.
        </StyledMeetBotPermissionText>
        <StyledMeetBotPermissionLink href="https://accounts.google.com/">
          Más información
        </StyledMeetBotPermissionLink>
      </StyledMeetBotPermissionContent>

      <StyledMeetBotPermissionContent>
        <StyledMeetBotPermissionText>
          Ver eventos en todos tus calendarios.
        </StyledMeetBotPermissionText>
        <StyledMeetBotPermissionLink href="https://accounts.google.com/">
          Más información
        </StyledMeetBotPermissionLink>
      </StyledMeetBotPermissionContent>

      <Button onClick={() => login()} type="primary" htmlType="button">
        Conectar con Google 🚀{' '}
      </Button>
    </StyledMeetBotContainer>
  )
}
export default MeetingPermissionsContainer
