import { http } from '../../config/http-common'
import IForgetPasswordUserData from '../../types/auth/forgetPassword'
import ILoginUserData from '../../types/auth/login'
import ILoginUserResponse from '../../types/auth/loginResponse'
import ILogoutUserData from '../../types/auth/logout'
import IResetPasswordUserData from '../../types/auth/resetPassword'

class AuthDataService {
  login(data: ILoginUserData) {
    return http.post<ILoginUserResponse>('/authentication/login/', data)
  }

  forgetPassword(data: IForgetPasswordUserData) {
    return http.post<IForgetPasswordUserData>(
      '/authentication/password/reset/',
      data
    )
  }

  resetPassword(data: IResetPasswordUserData) {
    return http.post<IResetPasswordUserData>(
      '/authentication/password/reset/confirm/',
      data
    )
  }

  logout(data: ILogoutUserData) {
    return http.post<ILogoutUserData>('/authentication/logout/', data)
  }
}

export default AuthDataService
