import React from 'react'
import { StyledH2 } from '../../styles/styledComponents'
import pdf from '../../assets/bots/pdf.png'
import csv from '../../assets/bots/csv.png'
import meet from '../../assets/bots/meet.png'
import {
  StyledCreateBotsMenuContainer,
  StyledCreateBotsMenuImg,
  StyledCreateBotsMenuImgContent
} from './styledComponents'

type PropsCreateBotsMenu = {
  handleNavigationCreateBot: (bot_type: string) => void
}

const CreateBotsMenu: React.FC<PropsCreateBotsMenu> = ({
  handleNavigationCreateBot
}) => {
  return (
    <StyledCreateBotsMenuContainer>
      <StyledH2>Choose bot type</StyledH2>
      <StyledCreateBotsMenuImgContent>
        <StyledCreateBotsMenuImg
          src={pdf}
          alt="PDF"
          onClick={() => handleNavigationCreateBot('PDF')}
        />
        <StyledCreateBotsMenuImg
          src={csv}
          alt="CSV"
          onClick={() => handleNavigationCreateBot('CSV')}
        />
        <StyledCreateBotsMenuImg
          src={meet}
          alt="MEET"
          onClick={() => handleNavigationCreateBot('MEET')}
        />
      </StyledCreateBotsMenuImgContent>
    </StyledCreateBotsMenuContainer>
  )
}

export default CreateBotsMenu
