import React from 'react'
import orgThumb from '../../assets/org/org.png'
import { Col, Row } from 'antd'
import {
  StyledOrganizationContainer,
  StyledOrganizationImg,
  StyledOrganizationText
} from './styledCompoents'
import { StyledH3, StyledH5 } from '../../styles/styledComponents'

type PropsOrganization = {
  org: Orgs.IOrgsResults
}

const Organization: React.FC<PropsOrganization> = ({ org }) => {
  return (
    <StyledOrganizationContainer>
      <Row gutter={16}>
        <Col xs={24} sm={8} md={8} lg={4}>
          <StyledOrganizationImg src={orgThumb} alt="org" />
        </Col>
        <Col xs={24} sm={16} md={16} lg={20}>
          <StyledH3>{org.name}</StyledH3>
          <p>Organization ID: {org.id}</p>
          <br />
          <StyledH5>Organization Owner</StyledH5>
          <br />
          <StyledOrganizationText>
            {org.users[0].first_name + ' ' + org.users[0].last_name}
          </StyledOrganizationText>
          <StyledOrganizationText>{org.users[0].email}</StyledOrganizationText>
        </Col>
      </Row>
      <br /> <br />
    </StyledOrganizationContainer>
  )
}

export default Organization
