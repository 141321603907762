import { StyledMenuIcon } from './styledComponents'
import bots from '../../assets/menu/bots.png'
import dashboard from '../../assets/menu/dashboard.png'
import organization from '../../assets/menu/organization.png'
import users from '../../assets/menu/users.png'
import meetings from '../../assets/menu/calendar.png'
import { Link } from 'react-router-dom'

export const menuList = [
  {
    path: '/dashboard',
    icon: (
      <Link to="/dashboard" key={'dasboard' + Math.random()}>
        <StyledMenuIcon src={dashboard} />
      </Link>
    ),
    hidden: true
  },
  {
    path: '/organization',
    icon: (
      <Link to="/organization" key={'organization' + Math.random()}>
        <StyledMenuIcon src={organization} />
      </Link>
    ),
    hidden: false
  },
  {
    path: '/users',
    icon: (
      <Link to="/users" key={'users' + Math.random()}>
        <StyledMenuIcon src={users} />
      </Link>
    ),
    hidden: false
  },
  {
    path: '/bots',
    icon: (
      <Link to="/bots" key={'bots' + Math.random()}>
        <StyledMenuIcon src={bots} />
      </Link>
    ),
    hidden: false
  },
  {
    path: '/meetings',
    icon: (
      <Link to="/meetings" key={'meetings' + Math.random()}>
        <StyledMenuIcon src={meetings} />
      </Link>
    ),
    hidden: false
  }
]
