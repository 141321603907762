import { createContext } from 'react'
import ILoginUserData from '../types/auth/login'
import ILogoutUserData from '../types/auth/logout'

export interface IJamyContext {
  isAuthenticated: boolean
  loading: boolean
  user: User.IUserDataResponse | null
  org: Orgs.IOrgsResults | null
  google_connection: boolean
  slack_connection: boolean
  login: (data: ILoginUserData) => void
  logout: (data: ILogoutUserData) => void
  getAllSlackUsers: () => void
}

export const initialState: IJamyContext = {
  isAuthenticated: false,
  loading: false,
  user: null,
  org: null,
  google_connection: false,
  slack_connection: false,
  login: (data: ILoginUserData) => {},
  logout: (data: ILogoutUserData) => {},
  getAllSlackUsers: () => {}
}

const JamyContext = createContext<IJamyContext>(initialState)

export default JamyContext
