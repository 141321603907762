import styled from 'styled-components'

export const StyledMeetBotContainer = styled.div`
  padding: 5%;
  background-color: white;
  border-radius: 10px;
`
export const StyledMeetBotPermissionContent = styled.div`
  margin: 20px 0;
`
export const StyledMeetBotPermissionText = styled.p`
  font-size: 16px;
  margin: 0;
`
export const StyledMeetBotPermissionLink = styled.a`
  font-size: 12px;
  color: #975db8;
  margin: 0;
`
