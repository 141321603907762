import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { Link } from 'react-router-dom'
import { BOT_TYPE, STATUS } from '../../../constants'

type PropsBotsTable = {
  data: Bots.IBotsResponse | undefined
  loading: boolean
}

const columns: ColumnsType<any> = [
  {
    title: 'BOT ID',
    dataIndex: 'id',
    key: 'id',
    render: (id, record) => (
      <Link key={id} to={`/bots/detail-bot/${id}`}>
        {record.name}
      </Link>
    )
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text
  },
  {
    title: 'DESCRIPTION',
    dataIndex: 'description',
    key: 'description',
    render: (description) => description
  },
  {
    title: 'TYPE',
    dataIndex: 'bot_type',
    key: 'bot_type',
    render: (bot_type) => BOT_TYPE[bot_type as keyof typeof BOT_TYPE]
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: (status) => STATUS[status as keyof typeof STATUS]
  },
  {
    title: 'OPTIONS',
    dataIndex: 'options',
    key: 'options',
    render: (id, record) => (
      <Button>
        <Link key={id} to={`/chat/${record.id}`}>
          Chat
        </Link>
      </Button>
    )
  }
]

const BotsTable: React.FC<PropsBotsTable> = ({ data, loading }) => {
  return (
    <Table
      columns={columns}
      dataSource={data ? data.results : []}
      loading={loading}
      rowKey="id"
    />
  )
}

export default BotsTable
