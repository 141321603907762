import React, { useContext } from 'react'
import HeaderComponent from '../../components/header'
import JamyContext from '../../context/jamyContext'

type PropsHeaderContainer = {
  title: string
}

const HeaderContainer: React.FC<PropsHeaderContainer> = ({ title }) => {
  const { user } = useContext(JamyContext)

  return <HeaderComponent title={title} email={user?.email} />
}

export default HeaderContainer
