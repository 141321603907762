import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'

type PropsUsersTable = {
  data: Invitations.Response | undefined
  loading: boolean
}

const columns: ColumnsType<any> = [
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email',
    render: (email) => email,
    width: '33%'
  },
  {
    title: 'TYPE',
    dataIndex: 'user_type',
    key: 'user_type',
    render: (user_type) => user_type.toUpperCase(),
    width: '33%'
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (status ? status.toUpperCase() : 'ACTIVE'),
    width: '33%'
  }
]

const UsersTable: React.FC<PropsUsersTable> = ({ data, loading }) => {
  return (
    <Table
      columns={columns}
      dataSource={data ? data.results : []}
      loading={loading}
      rowKey="id"
    />
  )
}

export default UsersTable
