import { Button, Col, Row } from 'antd'
import React from 'react'
import google from '../../assets/icons/google.png'
import slack from '../../assets/icons/slack.png'
import { StyledH3, StyledH5 } from '../../styles/styledComponents'
import orgThumb from '../../assets/org/org.png'
import {
  StyledProfileContainer,
  StyledProfileImg,
  StyledProfileText
} from './styledComponents'
import { Link } from 'react-router-dom'

type PropsProfile = {
  user: User.IUserDataResponse
  onClicButtonApp: (path: string) => void
  apps: {
    google_connection: boolean
    slack_connection: boolean
  }
}

const Profile: React.FC<PropsProfile> = ({ user, apps, onClicButtonApp }) => {
  return (
    <StyledProfileContainer>
      <Row gutter={16}>
        <Col xs={24} sm={8} md={8} lg={4}>
          <StyledProfileImg src={orgThumb} alt="org" />
        </Col>
        <Col xs={24} sm={16} md={16} lg={20}>
          <StyledH3>{user.first_name + ' ' + user.last_name}</StyledH3>
          <StyledProfileText>{user.email}</StyledProfileText>
          <StyledH5>{user.organization}</StyledH5>
        </Col>
      </Row>
      <br /> <br />
      <Row gutter={16}>
        <Col>
          <StyledH5>SERVICES CONNECTED</StyledH5>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <div style={{ display: 'flex' }}>
            <div style={{ margin: '10px 10px 10px 0' }}>
              <Button
                type="default"
                htmlType="button"
                style={{ width: '100%' }}
                disabled={apps.google_connection}
                onClick={() => onClicButtonApp('/bots/create-bot/meet')}
              >
                <div>
                  <img
                    src={google}
                    alt="google"
                    style={{ width: '20px' }}
                  ></img>
                </div>
              </Button>
              <p
                style={{ marginTop: '10px', fontSize: '12px' }}
                hidden={!apps.google_connection}
              >
                Connected
              </p>
            </div>
            <div style={{ margin: '10px' }}>
              {apps.slack_connection ? (
                <React.Fragment>
                  <Button
                    type="default"
                    htmlType="button"
                    style={{ width: '100%' }}
                    disabled={apps.slack_connection}
                  >
                    <div>
                      <img
                        src={slack}
                        alt="slack"
                        style={{ width: '20px' }}
                      ></img>
                    </div>
                  </Button>
                  <p
                    style={{ marginTop: '10px', fontSize: '12px' }}
                    hidden={!apps.slack_connection}
                  >
                    Connected
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Link
                    to={
                      process.env.REACT_APP_SLACK_CONNECTION
                        ? process.env.REACT_APP_SLACK_CONNECTION
                        : ''
                    }
                  >
                    <Button
                      type="default"
                      htmlType="button"
                      style={{ width: '100%' }}
                      disabled={apps.slack_connection}
                    >
                      <div>
                        <img
                          src={slack}
                          alt="slack"
                          style={{ width: '20px' }}
                        ></img>
                      </div>
                    </Button>
                  </Link>
                  <p
                    style={{ marginTop: '10px', fontSize: '12px' }}
                    hidden={!apps.slack_connection}
                  >
                    Connected
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </StyledProfileContainer>
  )
}

export default Profile
