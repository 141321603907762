import { http } from '../../config/http-common'

class BotsDataService {
  getAllBots() {
    return http.get<Bots.IBotsResponse>('/bots/')
  }
  getBotById(bot_id: string) {
    return http.get<Bots.IBotsResults>(`/bots/${bot_id}/`)
  }

  createBot(data: any) {
    return http.post<Bots.IBotsResults>(`/bots/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  updateBotById(bot_id: string, data: Bots.IBotsResults) {
    return http.patch<Bots.IBotsResults>(`/bots/${bot_id}/`, data)
  }
}

export default BotsDataService
