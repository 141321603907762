import { useForm } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import EditBotForm from '../../components/editBotForm'
import Loading from '../../components/loading'
import BotsDataService from '../../services/bots'
import { toast } from 'react-toastify'

const EditBotContainer: React.FC = () => {
  const [formEdit] = useForm()
  const [bot, setBot] = useState<Bots.IBotsResults | any>()
  const [documents, setDocuments] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [instruction, setInstruction] = useState<string>('')
  const [instructions, setInstructions] = useState<any[]>([])
  let { idBot } = useParams()

  useEffect(() => {
    const botsService = new BotsDataService()
    if (idBot) {
      botsService
        .getBotById(idBot)
        .then((bot) => {
          setBot(bot.data)
          setDocuments(bot.data.documents)
          setInstructions(bot.data.instructions)
          setLoading(false)
        })
        .catch((e) => console.log(e))
    }
  }, [idBot, formEdit])

  const onFinish = (data: Bots.IBotsResults) => {
    const botsServiceUpdate = new BotsDataService()
    data.instructions = instructions
    if (idBot) {
      botsServiceUpdate
        .updateBotById(idBot, data)
        .then((result) => {
          toast.success(`The bot ${result.data.name} has been updated`, {
            theme: 'colored'
          })
        })
        .catch((e) => console.log(e))
      toast.error(`Erro updating Bot, try again`, {
        theme: 'colored'
      })
    }
  }

  const addinstruction = () => {
    setInstructions([...instructions, instruction])
    formEdit.setFieldValue('instruction', '')
    setInstruction('')
  }

  const deleteInstruction = (value: String) => {
    const instructionsArray = instructions.filter((x) => x !== value)
    setInstructions(instructionsArray)
  }

  const onChangeInstuction = (value: string) => {
    setInstruction(value)
  }

  return !loading ? (
    <EditBotForm
      bot={bot}
      form={formEdit}
      documents={documents ? documents : []}
      instructions={instructions ? instructions : []}
      onFinish={onFinish}
      addinstruction={addinstruction}
      onChangeInstuction={onChangeInstuction}
      deleteInstruction={deleteInstruction}
    />
  ) : (
    <Loading />
  )
}

export default EditBotContainer
