import { http } from '../../config/http-common'

class MeetingsDataService {
  getAllMeetings() {
    return http.get<Meetings.Response>('/meetings/')
  }

  getMeetingsSearch(words: string) {
    return http.get<Meetings.Response>(`/meetings_search/?${words}`)
  }
}

export default MeetingsDataService
