import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

type HelmetProps = {
  title: string
}

const HelmetComponent: React.FC<HelmetProps> = ({ title }) => {
  const defaultTitle = 'Jamy'
  return (
    <HelmetProvider>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={title}
        titleTemplate={`%s | ${defaultTitle}`}
      ></Helmet>
    </HelmetProvider>
  )
}

export default HelmetComponent
