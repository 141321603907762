import styled from 'styled-components'

export const StyledMeetingItemContainer = styled.div`
  padding: 5%;
  background-color: white;
  border-radius: 20px;
  margin: 15px;
`
export const StyledMeetingItemImg = styled.img`
  width: 120px;
  margin: 0 auto;
`

export const StyledMeetingSummaryText = styled.div`
  margin: 0 0 10px 0;

  p {
    line-height: 20px;
  }
`
export const StyledMeetingCol = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  text-align: center;
`
export const StyledMeetingSeemore = styled.label`
  cursor: pointer;
  color: #e0aa25;
`
