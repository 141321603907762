import styled from 'styled-components'

export const StyledLoginFormContent = styled.div`
  padding: 20%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &&& {
    .ant-btn-primary {
      width: 100%;
    }

    h1 {
      text-align: center;
    }
  }
`
export const StyledLoginFormLink = styled.a`
  color: black;
  font-weight: 500;
  text-align: left;
`
