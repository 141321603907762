export const STATUS = {
  active: 'ACTIVE',
  pending: 'PENDING',
  error: 'ERROR'
}

export const BOT_TYPE = {
  CSV: 'TEXTO',
  PDF: 'PDF',
  MEETING: 'MEETING'
}
