import styled from 'styled-components'

export const StyledRegisterFormContent = styled.div`
  padding: 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &&& {
    .ant-btn-primary {
      width: 100%;
    }

    h1 {
      text-align: center;
    }
  }
`

export const StyledRegisterFormLink = styled.a`
  color: #5e6bd8;
  font-weight: 500;
  text-align: left;
`
export const StyledRegisterFormTexPassword = styled.p`
  text-align: right;
  font-size: 12px;
`
