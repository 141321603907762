import { http } from '../../config/http-common'

class UserDataService {
  create(data: User.ICreateUserData) {
    return http.post<User.ICreateUserResponse>('/users/', data)
  }

  getUser(user_id: string, acccesToken: string) {
    return http.get<User.IUserDataResponse>(`/users/${user_id}/`)
  }

  getAllUsers() {
    return http.get<any>('/users/')
  }
}

export default UserDataService
