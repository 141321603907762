import HeaderContainer from '../containers/headerContainer'
import MenuContainer from '../containers/menuContainer'
import { Container, ContainerContent, Content, Sider } from './styledComponents'

export const LayoutMain = ({ children, title }: any) => {
  return (
    <Container>
      <Sider>
        <MenuContainer />
      </Sider>
      <ContainerContent>
        <HeaderContainer title={title} />
        <Content
          style={{
            backgroundColor: '#F9F9F9',
            height: '90%',
            padding: '2%',
            borderRadius: '15px'
          }}
        >
          {children}
        </Content>
      </ContainerContent>
    </Container>
  )
}
