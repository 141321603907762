import { FormInstance } from 'antd'
import React from 'react'
import { Col, Form, Input, Row, Space, Tag } from 'antd'

type PropsSearchBar = {
  form: FormInstance
  searchBy: Array<string>
  onSearch: (data: string) => void
  onDeleteSearchText: (data: string) => void
}

const SearchBar: React.FC<PropsSearchBar> = ({
  form,
  searchBy,
  onDeleteSearchText,
  onSearch
}) => {
  return (
    <div style={{ margin: '15px' }}>
      <Row gutter={16}>
        <Col span={24}>
          <Form form={form}>
            <Form.Item name={'search'}>
              <Input.Search
                placeholder="Search by meeting name, keyworkds, contentn in summary or by participants"
                onSearch={onSearch}
              ></Input.Search>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Space size={[0, 8]} wrap>
            {searchBy.map((item, index) => {
              return (
                <Tag
                  color={'#E0AA25'}
                  closable
                  key={index}
                  onClose={() => onDeleteSearchText(item)}
                >
                  {item}
                </Tag>
              )
            })}
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default SearchBar
