import { useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'
import AuthDataService from '../services/auth'
import ILoginUserData from '../types/auth/login'
import JamyContext, { initialState } from './jamyContext'
import JamyReducer from './jamyReducer'
import { createBrowserHistory } from 'history'
import UserDataService from '../services/users'
import ILogoutUserData from '../types/auth/logout'
import Loading from '../components/loading'
import OrgsDataService from '../services/org'
import MeetUsersDataService from '../services/meetUsers'
import SlackUsersDataService from '../services/slackUsers'
export const browserHistory = createBrowserHistory()

const JamyState = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(JamyReducer, initialState)
  const authService = new AuthDataService()
  const userService = new UserDataService()
  const orgsService = new OrgsDataService()
  const meetUserService = new MeetUsersDataService()
  const slackUserService = new SlackUsersDataService()

  useEffect(() => {
    if (localStorage.getItem('access-token')) {
      const accessToken = localStorage.getItem('access-token')
      const id = localStorage.getItem('id')
      if (accessToken && id) {
        getUserInformation(id, accessToken)
        getOrgInformation()
        getAllMeetUsers()
        getAllSlackUsers()
      }

      dispatch({
        type: 'LOGIN',
        payload: { accessToken, id }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = async (data: ILoginUserData) => {
    dispatch({
      type: 'LOADING',
      payload: true
    })
    try {
      const res = await authService.login(data)
      localStorage.setItem('access-token', res.data.key)
      localStorage.setItem('id', res.data.user_id)
      await getUserInformation(res.data.user_id, res.data.key)
      dispatch({
        type: 'LOGIN',
        payload: res.data
      })
      await getOrgInformation()
      await getAllMeetUsers()
      await getAllSlackUsers()
      dispatch({
        type: 'LOADING',
        payload: false
      })
      browserHistory.push('/organization')
    } catch (e: any) {
      dispatch({
        type: 'LOADING',
        payload: false
      })
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const logout = async (data: ILogoutUserData) => {
    dispatch({
      type: 'LOADING',
      payload: true
    })
    try {
      const res = await authService.logout(data)
      dispatch({
        type: 'LOGOUT',
        payload: res.data
      })
      window.location.reload()
    } catch (e: any) {
      dispatch({
        type: 'LOADING',
        payload: false
      })
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getUserInformation = async (user_id: string, accessToken: string) => {
    try {
      const res = await userService.getUser(user_id, accessToken)
      dispatch({
        type: 'USER',
        payload: res.data
      })
    } catch (e: any) {
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getOrgInformation = async () => {
    try {
      const res = await orgsService.getOrganization()
      dispatch({
        type: 'ORG',
        payload: res.data.results[0]
      })
    } catch (e: any) {
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getAllMeetUsers = async () => {
    try {
      const res = await meetUserService.getAllMeetUsers()
      dispatch({
        type: 'GOOGLE_CONNECTION',
        payload:
          res.data.results.length > 0
            ? res.data.results[0].google_connection
            : false
      })
    } catch (e: any) {
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  const getAllSlackUsers = async () => {
    try {
      const res = await slackUserService.getAllSlackUsers()
      dispatch({
        type: 'SLACK_CONNECTION',
        payload:
          res.data.results.length > 0
            ? res.data.results[0].slack_connection
            : false
      })
    } catch (e: any) {
      toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
      console.log(e)
    }
  }

  return (
    <JamyContext.Provider
      value={{
        ...initialState,
        loading: state.loading,
        user: state.user,
        org: state.org,
        google_connection: state.google_connection,
        slack_connection: state.slack_connection,
        login,
        logout,
        getAllSlackUsers
      }}
    >
      {state.loading ? <Loading /> : children}
    </JamyContext.Provider>
  )
}

export default JamyState
