import styled from 'styled-components'
export const StyledFooterLinksContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 10px 0;

  ul li {
    display: inline;
    margin: 0 50px 0 0;
    font-weight: 800;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    display: none;
  }
`
