import { http } from '../../config/http-common'

class InvitationsDataService {
  getAllInvitations() {
    return http.get<Invitations.Response>('/invitations/')
  }

  createInvitation(data: Invitations.CreateInvitation) {
    return http.post<Invitations.Results>(`/invitations/`, data)
  }

  sendInvitation(data: Invitations.RegisterInvitation) {
    return http.post<any>(`/invitation/users/${data.invitation_id}/`, data)
  }
}

export default InvitationsDataService
