import { Col, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useContext } from 'react'
import FooterLinks from '../../components/footerLinks'
import LoginForm from '../../components/forms/login'
import LefSideRegister from '../../components/leftSideRegister'
import Loading from '../../components/loading'
import JamyContext from '../../context/jamyContext'
import { PublicRoute } from '../../hooks/useAuth'

import ILoginUserData from '../../types/auth/login'

const LoginContainer: React.FC = () => {
  const { login, loading } = useContext(JamyContext)
  const [formLogin] = useForm()

  const onFinish = (data: ILoginUserData) => {
    login(data)
    formLogin.resetFields()
  }

  return (
    <React.Fragment>
      <PublicRoute>
        <Row>
          <Col span={12}>
            <LefSideRegister />
            <FooterLinks />
          </Col>
          <Col span={12}>
            {!loading ? (
              <LoginForm form={formLogin} onFinish={onFinish} />
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </PublicRoute>
    </React.Fragment>
  )
}

export default LoginContainer
