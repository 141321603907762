import styled from 'styled-components'

export const StyledChatWindowContainer = styled.div``
export const StyledChatWindowContent = styled.div`
  padding: 2%;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
`
export const StyledChatWindowMessageContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledChatWindowMessageQuestion = styled.div`
  margin: 10px;
  width: 100%;
`
export const StyledChatWindowMessageAnswer = styled.div`
  margin: 10px;
  width: 100%;
`

export const StyledChatWindowMessageQuestionItem = styled.div`
  background-color: #fff6e0;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  word-wrap: break-word;
  float: right;
`
export const StyledChatWindowMessageAnswerItem = styled.div`
  background-color: #e2e7ff;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  word-wrap: break-word;
`

export const StyledChatWindowDateMessage = styled.div`
  font-size: 10px;
  margin: 10px 0 0 0;
  color: lightgray;
  width: 100%;
  text-align: right;
`
