import { Col, Row } from 'antd'
import React, { useState } from 'react'
import LefSideRegister from '../../components/leftSideRegister'
import FooterLinks from '../../components/footerLinks'
import Loading from '../../components/loading'
import { useNavigate, useParams } from 'react-router-dom'
import InvitationForm from '../../components/forms/invitationForm'
import { useForm } from 'antd/es/form/Form'
import {
  ValidatePassword,
  getPercentagePassword
} from '../../utils/validatePassword'
import { toast } from 'react-toastify'
import InvitationsDataService from '../../services/invitations'

export const InvitationContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [invitationForm] = useForm()
  const [percentagePassword, setPercentagePassword] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const { id_invitation, email } = useParams()
  const invitationService = new InvitationsDataService()
  const navigate = useNavigate()

  const onFinish = (data: Invitations.RegisterInvitation) => {
    setLoading(true)
    if (
      ValidatePassword(data.password) &&
      data.password === data.repeatPassword &&
      id_invitation &&
      email
    ) {
      data.invitation_id = id_invitation
      invitationService
        .sendInvitation(data)
        .then((response) => {
          toast.success('Registration success', { theme: 'colored' })
          setTimeout(() => {
            navigate('/login')
            navigate('/login')
          }, 1000)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        })
    } else {
      setLoading(false)
      toast.warning('Passwords are differents!', { theme: 'colored' })
    }
  }

  const onPasswordChange = (password: string) => {
    setPercentagePassword(getPercentagePassword(password))
  }

  const onFieldsChange = () => {
    const fields = [
      'first_name',
      'last_name',
      'email',
      'organization_name',
      'password',
      'repeatPassword'
    ]

    fields.forEach((element) => {
      if (!invitationForm.getFieldValue(element)) {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <LefSideRegister />
          <FooterLinks></FooterLinks>
        </Col>
        <Col span={12}>
          {!loading && email && id_invitation ? (
            <InvitationForm
              email={email}
              form={invitationForm}
              onFieldsChange={onFieldsChange}
              onFinish={onFinish}
              onPasswordChange={onPasswordChange}
              disableButton={disableButton}
              percentage={percentagePassword}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}
