import { Navigate, useLocation } from 'react-router-dom'

export const UseAuth = () => {
  const isAuthenticated = !!localStorage.getItem('access-token')
  return { isAuthenticated }
}

export const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated } = UseAuth()
  const location = useLocation()
  return isAuthenticated === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  )
}

export const PublicRoute = ({ children }: any) => {
  const { isAuthenticated } = UseAuth()
  const location = useLocation()
  return isAuthenticated === true ? (
    <Navigate to="/organization" replace state={{ path: location.pathname }} />
  ) : (
    children
  )
}
