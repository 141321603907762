import { useForm } from 'antd/es/form/Form'
import React, { useContext, useState } from 'react'
import CreateBotForm from '../../components/createBotForm'
import JamyContext from '../../context/jamyContext'
import BotsDataService from '../../services/bots'
import { useNavigate, useParams } from 'react-router-dom'

const CreateBotContainer: React.FC = () => {
  const { org } = useContext(JamyContext)
  const navigate = useNavigate()
  const [form] = useForm()
  const [files, setFiles] = useState<any>()
  const { bot_type } = useParams()
  const [instruction, setInstruction] = useState<string>('')
  const [instructions, setInstructions] = useState<string[]>([])
  const botService = new BotsDataService()

  // form.setFieldsValue({
  //   name: 'Otro Bot',
  //   certainty: 0.8,
  //   dont_know_response: 'respuesta',
  //   description: 'Descp',
  //   temperature: 0.9
  // })

  const formDataConvert = (data: Bots.IBotsResults) => {
    let formData = new FormData()
    const code = localStorage.getItem('code')
    for (var i = 0; i < instructions.length; i++) {
      formData.append('instructions', instructions[i])
    }
    formData.append('name', data.name)
    formData.append('temperature', JSON.stringify(data.temperature))
    formData.append('organization', org ? org.id : '')
    formData.append('bot_type', bot_type ? bot_type.toLocaleUpperCase() : '')
    formData.append('certainty', JSON.stringify(data.certainty))
    formData.append('dont_know_response', data.dont_know_response)
    formData.append('description', data.description)
    if (bot_type !== 'meeting') {
      formData.append('files', files)
    }

    if (bot_type === 'meeting') {
      formData.append('code', code ? code : '')
    }

    return formData
  }

  const onFinish = (data: Bots.IBotsResults) => {
    const obj = formDataConvert(data)
    if (org) {
      botService
        .createBot(obj)
        .then((response) => {
          navigate('/bots')
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const addinstruction = () => {
    setInstructions([...instructions, instruction])
    form.setFieldValue('instructions', '')
    setInstruction('')
  }

  const deleteInstruction = (value: String) => {
    const instructionsArray = instructions.filter((x) => x !== value)
    setInstructions(instructionsArray)
  }

  const onChangeInstuction = (value: string) => {
    setInstruction(value)
  }

  const loadDocuments = (file: any) => {
    setFiles(file.file)
    return false
  }

  return (
    <CreateBotForm
      botType={bot_type ? bot_type : ''}
      form={form}
      instructions={instructions}
      onFinish={onFinish}
      addinstruction={addinstruction}
      onChangeInstuction={onChangeInstuction}
      deleteInstruction={deleteInstruction}
      loadDocuments={loadDocuments}
    />
  )
}

export default CreateBotContainer
