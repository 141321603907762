import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import MeetingsContainer from '../../containers/meetingsContainer'

const MeetingsPage: React.FC = () => {
  return (
    <LayoutMain title={'MEETINGS'}>
      <HelmetComponent title="Meeting"></HelmetComponent>
      <MeetingsContainer />
    </LayoutMain>
  )
}

export default MeetingsPage
