import React, { useContext, useEffect, useState } from 'react'
import InvitationsDataService from '../../services/invitations'
import UsersTable from '../../components/tables/users'
import { Button, Col, Modal, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import CreateInvitationForm from '../../components/createInvitationForm'
import { toast } from 'react-toastify'
import JamyContext from '../../context/jamyContext'
import UserDataService from '../../services/users'
import { StyledH2 } from '../../styles/styledComponents'

const UsersContainer: React.FC = () => {
  const { org, user } = useContext(JamyContext)
  const [invitations, setInvitations] = useState<Invitations.Response>()
  const [users, setUsers] = useState<any>()
  const [formInvitations] = useForm()
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const invitationsService = new InvitationsDataService()
  const usersService = new UserDataService()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    getAllInvitations()
    getAllUserActives()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllInvitations = () => {
    setLoading(true)
    invitationsService
      .getAllInvitations()
      .then((response) => {
        setInvitations(response.data)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const getAllUserActives = () => {
    usersService
      .getAllUsers()
      .then((response) => {
        console.log(response)
        setUsers(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onFinish = (data: any) => {
    handleCancel()
    const invitation: Invitations.CreateInvitation = {
      email: data.email,
      user_type: data.user_type,
      organization: org ? org?.id : '',
      inviter: user ? user?.id : ''
    }

    invitationsService
      .createInvitation(invitation)
      .then((response) => {
        getAllInvitations()
        toast.success(
          `Mail inivitation to ${response.data.email} has been sent`,
          { theme: 'colored' }
        )
        formInvitations.resetFields()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <React.Fragment>
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={showModal}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <UsersTable data={users} loading={loading} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <StyledH2>INVITATIONS</StyledH2>
          <UsersTable data={invitations} loading={loading} />
        </Col>
      </Row>
      <Modal
        width={'70%'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CreateInvitationForm form={formInvitations} onFinish={onFinish} />
      </Modal>
    </React.Fragment>
  )
}

export default UsersContainer
