import { Button, Form, Input, Progress, Row } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import {
  MailOutlined,
  UserOutlined,
  BankOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined
} from '@ant-design/icons'
import React from 'react'
import {
  StyledRegisterFormContent,
  StyledRegisterFormLink,
  StyledRegisterFormTexPassword
} from './styledComponents'
import { StyledH1 } from '../../../styles/styledComponents'
import PopoverPasswords from '../../popovers/passwords'

type PropsRegisterForm = {
  onFinish: (data: User.ICreateUserData) => void
  onPasswordChange: (password: string) => void
  onFieldsChange: () => void
  form: FormInstance
  percentage: number
  disableButton: boolean
}

const RegisterForm: React.FC<PropsRegisterForm> = ({
  form,
  percentage,
  disableButton,
  onFieldsChange,
  onFinish,
  onPasswordChange
}) => {
  return (
    <StyledRegisterFormContent>
      <StyledH1>REGISTER</StyledH1>
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Form.Item name="first_name" rules={[{ required: true }]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Name"
          />
        </Form.Item>

        <Form.Item name="last_name" rules={[{ required: true }]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Last name"
          />
        </Form.Item>

        <Form.Item name="email" rules={[{ required: true }]}>
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="name@jamy.ai"
          />
        </Form.Item>
        <Form.Item
          name="organization_name"
          rules={[{ required: true }]}
          extra={
            'Once account is created you will be able to invite your contacts to be part of your organization.'
          }
        >
          <Input
            prefix={<BankOutlined className="site-form-item-icon" />}
            placeholder="Organization name"
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            onChange={(e) => onPasswordChange(e.target.value)}
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item name="repeatPassword" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Repeat password"
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Progress
          percent={percentage}
          showInfo={false}
          strokeColor={'#5e6bd8'}
        />
        <StyledRegisterFormTexPassword>
          Minimum password requirements <PopoverPasswords />
        </StyledRegisterFormTexPassword>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit" disabled={disableButton}>
              CREATE ACCOUNT
            </Button>
          )}
        </Form.Item>
      </Form>
      <Row>
        <StyledRegisterFormLink href="/login">
          Already have an account?
        </StyledRegisterFormLink>
      </Row>
    </StyledRegisterFormContent>
  )
}

export default RegisterForm
