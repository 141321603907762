import React from 'react'
import {
  StyledLeftSideRegisterContent,
  StyledLeftSideRegisterImg,
  StyledLeftSideRegisterLogo
} from './styledComponents'
import BannerRegister from '../../assets/register/bannerRegister.png'
import Logo from '../../assets/logo/logo.png'
import { StyledH1, StyledH2 } from '../../styles/styledComponents'

const LefSideRegister: React.FC = () => {
  return (
    <StyledLeftSideRegisterContent>
      <StyledLeftSideRegisterImg src={BannerRegister} />
      <StyledLeftSideRegisterLogo src={Logo} />
      <StyledH1>Jamy.ai</StyledH1>
      <StyledH2>Just ask</StyledH2>
    </StyledLeftSideRegisterContent>
  )
}

export default LefSideRegister
