import styled from 'styled-components'

export const StyledH1 = styled.h1`
  font-size: 40px;
  font-weight: 800;
  color: #5333c1;
`
export const StyledH2 = styled.h2`
  font-size: 20px;
  color: #975eb8;
`

export const StyledH3 = styled.h3`
  font-size: 30px;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
`

export const StyledH5 = styled.h5`
  font-size: 14px;
  color: rgb(224, 170, 37);
`
