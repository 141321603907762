import React, { useState } from 'react'
import {
  StyledMeetingItemContainer,
  StyledMeetingSeemore,
  StyledMeetingSummaryText
} from './styledComponents'
import { Button, Col, Row, Space, Tag } from 'antd'
import { StyledH2 } from '../../styles/styledComponents'

import moment from 'moment'
import SummaryViewer from '../modals/summary'

type PropsMeetingItem = {
  meeting: Meetings.Results
  onClicChat: (idBot: string) => void
}
const MeetingItem: React.FC<PropsMeetingItem> = ({ meeting, onClicChat }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <StyledMeetingItemContainer>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <video
            style={{ width: '100%', borderRadius: '10px' }}
            src={meeting.meeting_file}
            controls
          ></video>
        </Col>
        <Col xs={24} sm={10} md={10} lg={10}>
          <StyledH2>{meeting.name}</StyledH2>
          <p>{moment(meeting.created_date).format('DD-MM-YYYY HH:ss a')}</p>
          <br />
          <p>
            <strong>Participants</strong>
          </p>
          {meeting.participants.map((item, index) => {
            return <p key={index}>{item}</p>
          })}
        </Col>
        <Col xs={24} sm={2} md={2} lg={2}>
          <Button
            htmlType="button"
            type="primary"
            style={{ width: '100%' }}
            onClick={() => onClicChat(meeting.id)}
          >
            CHAT
          </Button>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <p>
            <strong>Summary</strong>
          </p>
          <StyledMeetingSummaryText>
            <p>
              {meeting.summary.substring(0, 700)} ...{' '}
              <StyledMeetingSeemore onClick={showModal}>
                See more
              </StyledMeetingSeemore>
              <SummaryViewer
                isModalOpen={isModalOpen}
                summary={meeting.summary}
                handleCancel={handleCancel}
              ></SummaryViewer>
            </p>
          </StyledMeetingSummaryText>

          <p>
            <strong>Tasks</strong>
          </p>
          {meeting.tasks.map((item, index) => {
            return <p key={index}>{item}</p>
          })}
          <br />
          <p>
            <strong>Keywords</strong>
          </p>
          <Space size={[0, 8]} wrap>
            {meeting.keywords.map((item, index) => {
              return (
                <Tag color="#5333c1" key={index}>
                  {item}
                </Tag>
              )
            })}
          </Space>
        </Col>
      </Row>
    </StyledMeetingItemContainer>
  )
}

export default MeetingItem
