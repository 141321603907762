import React from 'react'
import { StyledFooterLinksContent } from './styledComponents'

const FooterLinks: React.FC = () => {
  return (
    <StyledFooterLinksContent>
      <ul>
        <li>About</li>
        <li>Termns and Conditions</li>
        <li>Privacy Policy</li>
        <li>FAQ</li>
      </ul>
    </StyledFooterLinksContent>
  )
}

export default FooterLinks
