import { Modal } from 'antd'
import React from 'react'

type PropsSummaryViewer = {
  isModalOpen: boolean
  summary: string
  handleCancel: () => void
}

const SummaryViewer: React.FC<PropsSummaryViewer> = ({
  isModalOpen,
  summary,
  handleCancel
}) => {
  return (
    <Modal
      title={'SUMMARY'}
      open={isModalOpen}
      footer={[]}
      width={'60%'}
      onCancel={handleCancel}
    >
      <p style={{ padding: '2% 0' }}>{summary}</p>
    </Modal>
  )
}

export default SummaryViewer
