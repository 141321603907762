import React, { useEffect, useState } from 'react'
import MeetingsDataService from '../../services/meetings'
import { toast } from 'react-toastify'
import Loading from '../../components/loading'
import MeetingItem from '../../components/meetingItem'
import { useNavigate } from 'react-router-dom'
import { StyledMeetingsContainer } from './styledComponents'

import { useForm } from 'antd/es/form/Form'
import SearchBar from '../../components/searchBar'
import { Row } from 'antd'

const MeetingsContainer: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [loadingSearch, setloadingSearch] = useState(false)
  const [searchBarForm] = useForm()
  const [searchTextArray, setSearchTextArray] = useState<Array<string>>([])
  const [meetings, setMeetings] = useState<Meetings.Response>()
  const navigate = useNavigate()
  const meetingsService = new MeetingsDataService()

  const fetchData = async () => {
    const meetingService = new MeetingsDataService()
    try {
      const response = await meetingService.getAllMeetings()
      setMeetings(response.data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message, { theme: 'colored' })
      console.log(error)
    }
  }

  useEffect(() => {
    if (loading) {
      fetchData()
    }
  }, [loading])

  const onClicChat = (idBot: string, meeting_bot_id: string) => {
    navigate(`/meetChat/${meeting_bot_id}/${idBot}`)
  }

  const onDeleteSearchText = (data: string) => {
    setSearchTextArray(searchTextArray.filter((item) => item !== data))
  }

  const onSearch = (data: string) => {
    setSearchTextArray([...searchTextArray, data])
    searchBarForm.resetFields()
  }

  const getQueryParams = () => {
    let query = ''
    searchTextArray.forEach((element) => {
      query = query !== '' ? query + '&' : query
      // deepcode ignore GlobalReplacementRegex: <please specify a reason of ignoring this>
      query = query + `query=${element.replace(' ', '%20')}`
    })
    return query
  }

  useEffect(() => {
    setloadingSearch(true)
    meetingsService
      .getMeetingsSearch(getQueryParams())
      .then((response) => {
        setMeetings(response.data)
        setloadingSearch(false)
      })
      .catch((e) => {
        setloadingSearch(false)
        console.log(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextArray])

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <StyledMeetingsContainer>
          <SearchBar
            form={searchBarForm}
            searchBy={searchTextArray}
            onDeleteSearchText={onDeleteSearchText}
            onSearch={onSearch}
          />
          {loadingSearch ? (
            <Loading />
          ) : (
            <Row gutter={16}>
              {meetings?.results.map((item, index) => {
                return (
                  <MeetingItem
                    meeting={item}
                    key={index}
                    onClicChat={() => onClicChat(item.id, item.meeting_bot_id)}
                  />
                )
              })}
            </Row>
          )}
        </StyledMeetingsContainer>
      )}
    </React.Fragment>
  )
}

export default MeetingsContainer
