import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import React from 'react'
import { StyledCreateInvitationFormContainer } from './styledComponents'
import { StyledH1 } from '../../styles/styledComponents'

type PropsCreateInvitationForm = {
  form: FormInstance
  onFinish: (data: any) => void
}

const CreateInvitationForm: React.FC<PropsCreateInvitationForm> = ({
  form,
  onFinish
}) => {
  return (
    <StyledCreateInvitationFormContainer>
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Col>
            <StyledH1>Invite memebers </StyledH1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name={'email'}>
              <Input placeholder="name@jamy.ai"></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'user_type'}>
              <Select
                placeholder="Select user type"
                options={[
                  { value: 'OWNER', label: 'OWNER' },
                  { value: 'USER', label: 'USER' }
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button type="primary" htmlType="submit">
              SEND INVITATION
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledCreateInvitationFormContainer>
  )
}

export default CreateInvitationForm
