import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ProtectedRoute, PublicRoute } from '../hooks/useAuth'
import ForgetPasswordPage from './authentication/forgetPassword'
import LoginPage from './authentication/login'
import RegistrationPage from './authentication/registration'
import ResetPasswordPage from './authentication/resetPassword'
import { BotsPage } from './bots'
import CreateBotPage from './bots/create'
import EditBotPage from './bots/edit'
import DashboardPage from './dashboard'
import { OrganizationPage } from './organization'
import { ProfilePage } from './profile'
import { UsersPage } from './users'
import MeetingsPermissionsPage from './bots/meetings'
import MeetingsPage from './meetings'
import ChatPage from './bots/chat'
import InvitationPage from './authentication/invitation'

export const Main: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <RegistrationPage />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute>
            <RegistrationPage />
          </PublicRoute>
        }
      />
      <Route
        path="/signup/invitations/:id_invitation/:email"
        element={
          <PublicRoute>
            <InvitationPage />
          </PublicRoute>
        }
      />
      <Route
        path="/forgetPassword"
        element={
          <PublicRoute>
            <ForgetPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path="/resetPassword"
        element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        }
      >
        <Route
          path=":token?"
          element={
            <PublicRoute>
              <ResetPasswordPage />
            </PublicRoute>
          }
        />
      </Route>
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/organization"
        element={
          <ProtectedRoute>
            <OrganizationPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile/:code"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots"
        element={
          <ProtectedRoute>
            <BotsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots/create-bot/:bot_type"
        element={
          <ProtectedRoute>
            <CreateBotPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots/detail-bot/:idBot"
        element={
          <ProtectedRoute>
            <EditBotPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots/create-bot/meet"
        element={
          <ProtectedRoute>
            <MeetingsPermissionsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/meetings"
        element={
          <ProtectedRoute>
            <MeetingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat/:idBot"
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/meetChat/:meeting_bot_id/:idBot"
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}
