import React from 'react'
import { StyledH1 } from '../../styles/styledComponents'
import {
  StyledHeaderContent,
  StyledHeaderLinkProfile
} from './styledComponents'

type PropsHeader = {
  title: string | undefined
  email?: string | null
}

const HeaderComponent: React.FC<PropsHeader> = ({ title, email }) => {
  return (
    <StyledHeaderContent>
      <StyledH1>{title}</StyledH1>
      <StyledHeaderLinkProfile href="/profile">{email}</StyledHeaderLinkProfile>
    </StyledHeaderContent>
  )
}

export default HeaderComponent
