import React, { useContext } from 'react'
import Menu from '../../components/menu'
import JamyContext from '../../context/jamyContext'

const MenuContainer: React.FC = () => {
  const { logout } = useContext(JamyContext)
  const accessToken = localStorage.getItem('access-token')

  const handleLogout = () => {
    if (accessToken) {
      logout({ accesToken: accessToken })
    }
  }

  return <Menu handleLogout={handleLogout} />
}

export default MenuContainer
