import { Col, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import FooterLinks from '../../components/footerLinks'
import RegisterForm from '../../components/forms/registration'
import LefSideRegister from '../../components/leftSideRegister'
import Loading from '../../components/loading'
import UserDataService from '../../services/users'
import {
  getPercentagePassword,
  ValidatePassword
} from '../../utils/validatePassword'

const RegisterContainer: React.FC = () => {
  const [percentagePassword, setPercentagePassword] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const [registerForm] = useForm<User.ICreateUserData>()
  const userService = new UserDataService()

  const onFinish = (data: User.ICreateUserData) => {
    setLoading(true)
    if (
      ValidatePassword(data.password) &&
      data.password === data.repeatPassword
    ) {
      userService
        .create(data)
        .then((response) => {
          registerForm.resetFields()
          setPercentagePassword(0)
          setDisableButton(true)
          setLoading(false)
        })
        .then(() => {
          toast.success('Registro exitoso', { theme: 'colored' })
        })
        .catch((e) => {
          toast.error('Ha ocurrido un error', { theme: 'colored' })
          setLoading(false)
        })
    } else {
      setLoading(false)
      toast.warning('Las contraseñas son diferentes', { theme: 'colored' })
    }
  }

  const onPasswordChange = (password: string) => {
    setPercentagePassword(getPercentagePassword(password))
  }

  const onFieldsChange = () => {
    const fields = [
      'first_name',
      'last_name',
      'email',
      'organization_name',
      'password',
      'repeatPassword'
    ]

    fields.forEach((element) => {
      if (!registerForm.getFieldValue(element)) {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <LefSideRegister />
          <FooterLinks></FooterLinks>
        </Col>
        <Col span={12}>
          {!loading ? (
            <RegisterForm
              form={registerForm}
              percentage={percentagePassword}
              onFinish={onFinish}
              onPasswordChange={onPasswordChange}
              onFieldsChange={onFieldsChange}
              disableButton={disableButton}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RegisterContainer
