import React from 'react'
import {
  StyledMenuContent,
  StyledMenuItemsContent,
  StyledMenuLogo,
  StyledMenuSide,
  StyledMenuLogout
} from './styledComponents'
import logo from '../../assets/logo/logo.png'
import logout from '../../assets/menu/logout.png'
import { menuList } from './data'

type PropsMenu = {
  handleLogout: () => void
}

const Menu: React.FC<PropsMenu> = ({ handleLogout }) => {
  return (
    <StyledMenuContent>
      <StyledMenuSide>
        <StyledMenuLogo src={logo} />
        <StyledMenuItemsContent>
          {menuList
            .filter((x) => x.hidden !== true)
            .map((item) => {
              return item.icon
            })}
        </StyledMenuItemsContent>
        <StyledMenuLogout src={logout} onClick={handleLogout} />
      </StyledMenuSide>
    </StyledMenuContent>
  )
}

export default Menu
